/* Ensure the Inter font is imported in your HTML or CSS file */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

body, html, #root {
  margin: 0;
  font-family: 'Inter', sans-serif; /* Apply Inter font globally */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
