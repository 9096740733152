.App {
  text-align: center;
  font-family: 'Inter', sans-serif; /* Apply Inter font */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Inter', sans-serif; /* Apply Inter font */
}

.App-link {
  color: #61dafb;
  font-family: 'Inter', sans-serif; /* Apply Inter font */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Compact table row padding with !important */
.compact-table .ant-table-tbody > tr > td {
  padding: 6px !important; /* Smaller padding with !important */
  font-size: 14px !important; /* Font size adjustment */
}

.compact-table .ant-table-thead > tr > th {
  padding: 4px !important; /* Header cell padding with !important */
}

/* Style to move the filter icon closer to the column name */
.compact-table .ant-table-filter-column .ant-table-filter-trigger {
  display: inline-flex;
  align-items: center;
   /* Adjust as necessary to bring it closer to the column title */
  margin-right: 14px; /* Move it closer to the column name */
}

.joyride-tooltip {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.joyride-tooltip-active {
  opacity: 1;
  transform: translateY(0);
}

.joyride-custom-arrow {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  position: absolute;
  top: -8px; /* Adjust based on tooltip position */
  left: calc(50% - 8px);
  transform: rotate(45deg);
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
  z-index: -1; /* Keep it behind the tooltip */
}
